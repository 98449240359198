<template>
  <article>
    <h1 class="text-3xl text-gray-700 font-semibold my-6">{{campus}}</h1>
    <p v-for="(p,index) in history" :key="index">{{p}}</p>
    <section class="mt-10 hidden sm:block">
      <table class="w-full rounded-t-xl overflow-hidden border border-green-900">
        <thead class="w-full">
        <th v-for="(title,index) in titles" :key="index" :colspan="index == 2 ? 2:1" >{{title}}</th>
        </thead>
        <tbody>
        <tr v-for="librarian in librarians" :key="librarian.year">
          <td>{{librarian.name}}</td>
          <td>{{librarian.year}}</td>
          <td>{{librarian.position}}</td>
          <td>{{librarian.headNames}}</td>
        </tr>
        </tbody>
      </table>
    </section>
    <section class="mt-10 sm:hidden">
      <div v-for="librarian in librarians" :key="librarian.year" class="bg-white  my-3 shadow-xl rounded-xl text-left overflow-hidden">
        <div class="flex justify-center bg-green-700 text-white mb-4 py-2">
          <h3 class="font-bold text-xl">{{librarian.year}}</h3>
        </div>
        <div class="p-2">
          <h5 class="ml-4 text-lg"><i class="text-green-700">Name:</i> {{librarian.name}}</h5>
          <h5 class="ml-4 text-lg"><i  class="text-green-700">Superintendent/President:</i> </h5>
          <h5 class="ml-6 text-lg italic">{{librarian.position}}</h5>
          <h5 class="ml-8 text-lg">{{librarian.headNames}}</h5>
        </div>
      </div>
    </section>
  </article>
</template>

<script>
export default {
  name: "HistoryDetails",
  props:['librarians','campus','titles','history']
}
</script>

<style scoped>
p{
  @apply my-2 text-lg text-gray-800;
  text-indent: 2rem;
}
h5{
  @apply text-gray-600
}
span{
  @apply text-green-900 italic
}
th{
  @apply text-center bg-green-700 text-white py-2 text-lg
}
tr{
  @apply border border-green-900
}
td{
  @apply text-lg p-2 border-green-900;
}
</style>