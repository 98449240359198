<template>
  <article class="bg-white text-lg rounded-xl overflow-hidden">
    <div class="bg-green-700 p-2 text-center uppercase font-semibold text-white">
      <h1>Related Links</h1>
    </div>
    <div class="p-2">
      <div v-for="(link,index) in links" :key="index" class="flex items-center text-green-700 py-2">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
        </svg>
        <router-link :to="link.link" class="">{{link.name}}</router-link>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: "HistoryRelatedLinks",
  data:()=>({
    links: [
      {
        name: 'Main Campus',
        link: '/history/main-history'
      },
      {
        name: 'Calapan City Campus',
        link: '/history/calapan-city-history'
      },
      {
        name: 'Bongabong Campus',
        link: '/history/bongabong-history'
      },
    ]
  })
}
</script>

<style scoped>

</style>